import React from 'react';

import { speechBubblePositions, tipPositions } from './tooltip.core';
import type { TooltipProps } from './tooltip.types';
import { TooltipPosition } from './tooltip.types';

export const Tooltip: React.FC<TooltipProps> = ({
  content,
  position = TooltipPosition.BottomLeft,
  hide = false,
  hideTip = false,
  children,
  ...props
}) => (
  <div
    className='relative inline-block group/tooltip'
    data-testid='tooltip'
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}>
    {children}
    {!hide && content && (
      <div
        className={`absolute z-20 flex w-60 flex-col gap-2 rounded-lg bg-NeutralDarkDark p-5 text-xs leading-[14px] text-NeutralLightLightest ${speechBubblePositions[position]} opacity-0 group-hover/tooltip:opacity-100 transition-opacity duration-300 pointer-events-none group-hover/tooltip:pointer-events-auto`}>
        {content.title && <span className='font-bold'>{content.title}</span>}
        {content.description && <span className='font-normal'>{content.description}</span>}
        {!hideTip && <div className={`absolute h-3 w-3 bg-NeutralDarkDark ${tipPositions[position]}`} />}
      </div>
    )}
  </div>
);
