import React from 'react';

import {
  AlertIcon,
  BurgerMenuIcon,
  CheckCircleIcon,
  CloseIcon,
  CompactIcon,
  DisconnectIcon,
  ErrorIcon,
  ExclamationCircleIcon,
  GitBranchIcon,
  InfoIcon,
  LaptopIcon,
  LightningIcon,
  LightningOutlineIcon,
  MagicWandIcon,
  PathIcon,
  PlusIcon,
  ProhibitIcon,
  SearchIcon,
  SparkleIcon,
  SuccessIcon,
  WarningIcon,
} from './icons';
import type { IconsType } from './icons.types';

export const Icons: IconsType = {
  Laptop: (props) => <LaptopIcon {...props} />,
  CheckCircle: (props) => <CheckCircleIcon {...props} />,
  Close: (props) => <CloseIcon {...props} />,
  Error: (props) => <ErrorIcon {...props} />,
  ExclamationCircle: (props) => <ExclamationCircleIcon {...props} />,
  Warning: (props) => <WarningIcon {...props} />,
  Success: (props) => <SuccessIcon {...props} />,
  Info: (props) => <InfoIcon {...props} />,
  Prohibit: (props) => <ProhibitIcon {...props} />,
  Alert: (props) => <AlertIcon {...props} />,
  Disconnect: (props) => <DisconnectIcon {...props} />,
  MagicWand: (props) => <MagicWandIcon {...props} />,
  Sparkle: (props) => <SparkleIcon {...props} />,
  GitBranch: (props) => <GitBranchIcon {...props} />,
  Compact: (props) => <CompactIcon {...props} />,
  BurgerMenu: (props) => <BurgerMenuIcon {...props} />,
  Search: (props) => <SearchIcon {...props} />,
  Lightning: (props) => <LightningIcon {...props} />,
  LightningOutline: (props) => <LightningOutlineIcon {...props} />,
  Path: (props) => <PathIcon {...props} />,
  Plus: (props) => <PlusIcon {...props} />,
};
