export enum EventTypes {
  AlertDismissed = 'alert-dismissed',
  AlertDisplayed = 'alert-displayed',
  CustomEvent = 'custom-event',
  InputChanged = 'input-changed',
  ItemClicked = 'item-clicked',
  ModalClosed = 'modal-closed',
  ModalOpened = 'modal-opened',
  PageVisited = 'page-visited',
  SurveyResponse = 'survey sent',
  Warning = 'warning',
}
