import { useAutoAnimate } from '@formkit/auto-animate/react';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { POSTHOG_URL } from '@/analytics/constants/constants';
import { useFeatureFlags } from '@/levity/feature-flags';
import { env } from '@/lib/env';
import { api } from '@/utils/api';
import { ENVIRONMENTS } from '@/utils/constants';

import { useIdentifyUser } from '../analytics';
import { initializeEvents } from '../analytics/tracking';
import { useAuthSession } from '../levity/auth/compatibility-components';
import { LoadingLogo } from './loading-logo';

export interface EventLayerProps {
  children: ReactNode;
}
export const EventLayer = ({ children }: EventLayerProps) => {
  const session = useAuthSession();

  const [posthogInitialized, setPosthogInitialized] = useState(false);
  const [isAppReady, setIsAppReady] = useState<boolean>(false);
  const { featureFlags, setFeatureFlags } = useFeatureFlags();

  useIdentifyUser(posthogInitialized, session);

  const [aimationParent] = useAutoAnimate();

  useEffect(() => {
    initializeEvents({
      apiKey: env.NEXT_PUBLIC_POSTHOG_API!,
      apiUrl:
        env.NEXT_PUBLIC_NODE_ENV! === ENVIRONMENTS.DEVELOPMENT ? POSTHOG_URL : env.NEXT_PUBLIC_ANALYTICS_ENDPOINT!,
    });

    setPosthogInitialized(true);
  }, []);

  api.AnalyticsRouter.getFeatureFlags.useQuery(
    {},
    {
      enabled: !featureFlags,
      onSettled: (data) => {
        if (data && posthogInitialized && setFeatureFlags) setFeatureFlags(data);

        setIsAppReady(true);
      },
    },
  );

  return (
    <div>
      {isAppReady ? (
        children
      ) : (
        <div role='status' className='flex h-full w-full items-center justify-center' ref={aimationParent}>
          <LoadingLogo />
          <span className='sr-only'>Loading...</span>
        </div>
      )}
    </div>
  );
};
