import { z } from 'zod';

import { ENVIRONMENTS } from '../utils/constants';

/**
 * Specify your server-side environment variables schema here. This way you can ensure the app isn't
 * built with invalid env vars.
 */
const server = z.object({
  APP_ORIGIN: z.string().optional(),
  NODE_ENV: z.enum([ENVIRONMENTS.DEVELOPMENT, ENVIRONMENTS.TEST, ENVIRONMENTS.PRODUCTION, ENVIRONMENTS.PRE_PRODUCTION]),

  // Auth
  AUTH_EMAIL_SERVER: z.string(),
  AUTH_EMAIL_FROM: z.string().email(),
  AUTH_EMAIL_SERVER_PASSWORD: z.string(),
  AUTH_OAUTH_API_CALL_VALIDATION_STRING: z.string(),

  // LLM Connections
  LLM_OPEN_AI_KEY: z.string(),
  LLM_AZURE_FRANCE_OPENAI_KEY: z.string(),
  LLM_AZURE_UK_OPENAI_KEY: z.string(),
  // Frontegg
  FRONTEGG_APP_URL: z.string().url(),
  FRONTEGG_BASE_URL: z.string().url(),
  FRONTEGG_CLIENT_ID: z.string(),
  FRONTEGG_CLIENT_SECRET: z.string(),
  FRONTEGG_COOKIE_NAME: z.string(),
  FRONTEGG_ENCRYPTION_PASSWORD: z.string(),
  FRONTEGG_PUBLIC_KEY: z.string(),
  FRONTEGG_WEBHOOK_SECRET: z.string(),
  GOOGLE_CLIENT_ID: z.string(),
  GOOGLE_CLIENT_SECRET: z.string(),
  APP_HOST: z.string(),
  // Integrations
  INTEGRATIONS_NANGO_USERNAME: z.string(),
  INTEGRATIONS_NANGO_PASSWORD: z.string(),
  INTEGRATIONS_NANGO_ZENDESK_CID: z.string(),
  INTEGRATIONS_NANGO_ZENDESK_CSEC: z.string(),
  INTEGRATIONS_NANGO_FRONT_CID: z.string(),
  INTEGRATIONS_NANGO_FRONT_CSEC: z.string(),
  INTEGRATIONS_NANGO_GOOGLE_CID: z.string(),
  INTEGRATIONS_NANGO_GOOGLE_CSEC: z.string(),
  INTEGRATIONS_NANGO_GOOGLE_SHEETS_CID: z.string(),
  INTEGRATIONS_NANGO_GOOGLE_SHEETS_CSEC: z.string(),
  INTEGRATIONS_NANGO_AIRTABLE_CID: z.string(),
  INTEGRATIONS_NANGO_AIRTABLE_CSEC: z.string(),
  INTEGRATIONS_NANGO_MICROSOFT_CID: z.string(),
  INTEGRATIONS_NANGO_MICROSOFT_CSEC: z.string(),
  INTEGRATIONS_NANGO_SLACK_CID: z.string(),
  INTEGRATIONS_NANGO_SLACK_CSEC: z.string(),
  INTEGRATIONS_NANGO_HUBSPOT_CID: z.string(),
  INTEGRATIONS_NANGO_HUBSPOT_CSEC: z.string(),
  // Payments
  PAYMENTS_PROVIDER_PADDLE_API_KEY: z.string(),
  PAYMENTS_PROVIDER_PADDLE_HOST: z.string().url(),
  PAYMENTS_PROVIDER_PADDLE_PUBLIC_KEY: z.string(),
  PAYMENTS_PROVIDER_PADDLE_SECRET: z.string(),
  PAYMENTS_PROVIDER_PADDLE_VENDOR_ID: z.number(),
  PAYMENTS_PROVIDER_PADDLE_PRODUCT_ID_MONTHLY: z.number(),
  PAYMENTS_PROVIDER_PADDLE_PRODUCT_ID_YEARLY: z.number(),
  // Workflows
  WORKFLOWS_API_CALL_VALIDATION_STRING: z.string(),
  WORKFLOWS_TEMPORAL_HOSTNAME: z.string(),
  GCP_TALON_ENDPOINT: z.string(),
  // Security
  USERFLOW_API_KEY: z.string(),

  // Observability
  OBSERVABILITY_DATADOG_LOGGING_KEY: z.string(),

  // Emails
  SMTP_HOST: z.string(),

  // Slack
  ENABLE_SLACK_NOTIFICATIONS: z.boolean(),
  SLACK_TOKEN: z.string(),

  // Network Integration
  NETWORK_INTEGRATION_TIMEOUT: z.number().positive(),
  NETWORK_INTEGRATION_MAX_CONTENT_LENGTH: z.number().positive(),

  // Worker
  WORKFLOWS_TEMPORAL_NAMESPACE: z.string(),
  WORKFLOWS_TEMPORAL_CERT: z.string(),
  WORKFLOWS_TEMPORAL_CERT_KEY: z.string(),
  TEMPORAL_PORT: z.string(),
  FLOW_EXECUTION_TASK_QUEUE: z.string(),
});

/**
 * Specify your client-side environment variables schema here. This way you can ensure the app isn't
 * built with invalid env vars. To expose them to the client, prefix them with `NEXT_PUBLIC_`.
 */
const client = z.object({
  // NEXT_PUBLIC_CLIENTVAR: z.string().min(1),
  NEXT_PUBLIC_ANALYTICS_ENDPOINT: z.string().url(),
  NEXT_PUBLIC_HUBSPOT_ID: z.string(),
  NEXT_PUBLIC_IS_ANALYTICS_EVENTS_ENABLED: z.boolean(),
  NEXT_PUBLIC_NANGO_HOST: z.string(),
  NEXT_PUBLIC_NODE_ENV: z.enum([
    ENVIRONMENTS.DEVELOPMENT,
    ENVIRONMENTS.TEST,
    ENVIRONMENTS.PRODUCTION,
    ENVIRONMENTS.PRE_PRODUCTION,
  ]),
  NEXT_PUBLIC_POSTHOG_API: z.string(),
  NEXT_PUBLIC_USERFLOW_KEY: z.string(),
  NEXT_PUBLIC_NEXTAUTH_URL: z.string(),
  NEXT_PUBLIC_GTM_ID: z.string(),
  NEXT_PUBLIC_FRESHDESK_API_KEY: z.string(),
  NEXT_PUBLIC_FRESHDESK_ENPAL_API_KEY: z.string(),
});

/**
 * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
 * middlewares) or client-side so we need to destruct manually.
 *
 * @type {Record<keyof z.infer<typeof server> | keyof z.infer<typeof client>, string | undefined>}
 */
const processEnv = {
  APP_ORIGIN: process.env.APP_ORIGIN!,
  APP_HOST: process.env.APP_HOST!,
  AUTH_OAUTH_API_CALL_VALIDATION_STRING: process.env.AUTH_OAUTH_API_CALL_VALIDATION_STRING!,
  AUTH_EMAIL_FROM: process.env.AUTH_EMAIL_FROM!,
  AUTH_EMAIL_SERVER_PASSWORD: process.env.AUTH_EMAIL_SERVER_PASSWORD!,
  AUTH_EMAIL_SERVER: process.env.AUTH_EMAIL_SERVER!,
  FRONTEGG_APP_URL: process.env.FRONTEGG_APP_URL!,
  FRONTEGG_BASE_URL: process.env.FRONTEGG_BASE_URL!,
  FRONTEGG_CLIENT_ID: process.env.FRONTEGG_CLIENT_ID!,
  FRONTEGG_CLIENT_SECRET: process.env.FRONTEGG_CLIENT_SECRET!,
  FRONTEGG_COOKIE_NAME: process.env.FRONTEGG_COOKIE_NAME!,
  FRONTEGG_ENCRYPTION_PASSWORD: process.env.FRONTEGG_ENCRYPTION_PASSWORD!,
  FRONTEGG_PUBLIC_KEY: process.env.FRONTEGG_PUBLIC_KEY!,
  FRONTEGG_WEBHOOK_SECRET: process.env.FRONTEGG_WEBHOOK_SECRET!,
  GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID!,
  GOOGLE_CLIENT_SECRET: process.env.GOOGLE_CLIENT_SECRET!,
  NEXT_PUBLIC_IS_ANALYTICS_EVENTS_ENABLED: process.env.NEXT_PUBLIC_IS_ANALYTICS_EVENTS_ENABLED === 'true',
  INTEGRATIONS_NANGO_GOOGLE_CID: process.env.INTEGRATIONS_NANGO_GOOGLE_CID!,
  INTEGRATIONS_NANGO_GOOGLE_CSEC: process.env.INTEGRATIONS_NANGO_GOOGLE_CSEC!,
  INTEGRATIONS_NANGO_GOOGLE_SHEETS_CID: process.env.INTEGRATIONS_NANGO_GOOGLE_SHEETS_CID!,
  INTEGRATIONS_NANGO_GOOGLE_SHEETS_CSEC: process.env.INTEGRATIONS_NANGO_GOOGLE_SHEETS_CSEC!,
  INTEGRATIONS_NANGO_AIRTABLE_CID: process.env.INTEGRATIONS_NANGO_AIRTABLE_CID!,
  INTEGRATIONS_NANGO_AIRTABLE_CSEC: process.env.INTEGRATIONS_NANGO_AIRTABLE_CSEC!,
  INTEGRATIONS_NANGO_MICROSOFT_CID: process.env.INTEGRATIONS_NANGO_MICROSOFT_CID!,
  INTEGRATIONS_NANGO_HUBSPOT_CID: process.env.INTEGRATIONS_NANGO_HUBSPOT_CID!,
  INTEGRATIONS_NANGO_HUBSPOT_CSEC: process.env.INTEGRATIONS_NANGO_HUBSPOT_CSEC!,
  INTEGRATIONS_NANGO_MICROSOFT_CSEC: process.env.INTEGRATIONS_NANGO_MICROSOFT_CSEC!,
  INTEGRATIONS_NANGO_PASSWORD: process.env.INTEGRATIONS_NANGO_PASSWORD!,
  INTEGRATIONS_NANGO_USERNAME: process.env.INTEGRATIONS_NANGO_USERNAME!,
  INTEGRATIONS_NANGO_ZENDESK_CID: process.env.INTEGRATIONS_NANGO_ZENDESK_CID!,
  INTEGRATIONS_NANGO_ZENDESK_CSEC: process.env.INTEGRATIONS_NANGO_ZENDESK_CSEC!,
  INTEGRATIONS_NANGO_FRONT_CID: process.env.INTEGRATIONS_NANGO_FRONT_CID!,
  INTEGRATIONS_NANGO_FRONT_CSEC: process.env.INTEGRATIONS_NANGO_FRONT_CSEC!,
  INTEGRATIONS_NANGO_SLACK_CID: process.env.INTEGRATIONS_NANGO_SLACK_CID!,
  INTEGRATIONS_NANGO_SLACK_CSEC: process.env.INTEGRATIONS_NANGO_SLACK_CSEC!,
  LLM_AZURE_FRANCE_OPENAI_KEY: process.env.LLM_AZURE_FRANCE_OPENAI_KEY!,
  LLM_OPEN_AI_KEY: process.env.LLM_OPEN_AI_KEY!,
  LLM_AZURE_UK_OPENAI_KEY: process.env.LLM_AZURE_UK_OPENAI_KEY!,
  NEXT_PUBLIC_ANALYTICS_ENDPOINT: process.env.NEXT_PUBLIC_ANALYTICS_ENDPOINT!,
  NEXT_PUBLIC_HUBSPOT_ID: process.env.NEXT_PUBLIC_HUBSPOT_ID!,
  NEXT_PUBLIC_NANGO_HOST: process.env.NEXT_PUBLIC_NANGO_HOST!,
  NEXT_PUBLIC_NEXTAUTH_URL: process.env.NEXT_PUBLIC_NEXTAUTH_URL!,
  NEXT_PUBLIC_NODE_ENV: process.env.NODE_ENV!,
  NEXT_PUBLIC_POSTHOG_API: process.env.NEXT_PUBLIC_POSTHOG_API!,
  NEXT_PUBLIC_USERFLOW_KEY: process.env.NEXT_PUBLIC_USERFLOW_KEY!,
  NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID!,
  NODE_ENV: process.env.NODE_ENV!,
  PAYMENTS_PROVIDER_PADDLE_API_KEY: process.env.PAYMENTS_PROVIDER_PADDLE_API_KEY!,
  PAYMENTS_PROVIDER_PADDLE_HOST: process.env.PAYMENTS_PROVIDER_PADDLE_HOST!,
  PAYMENTS_PROVIDER_PADDLE_PRODUCT_ID_MONTHLY: Number(process.env.PAYMENTS_PROVIDER_PADDLE_PRODUCT_ID_MONTHLY!),
  PAYMENTS_PROVIDER_PADDLE_PRODUCT_ID_YEARLY: Number(process.env.PAYMENTS_PROVIDER_PADDLE_PRODUCT_ID_YEARLY!),
  PAYMENTS_PROVIDER_PADDLE_PUBLIC_KEY: process.env.PAYMENTS_PROVIDER_PADDLE_PUBLIC_KEY!,
  PAYMENTS_PROVIDER_PADDLE_SECRET: process.env.PAYMENTS_PROVIDER_PADDLE_SECRET!,
  PAYMENTS_PROVIDER_PADDLE_VENDOR_ID: Number(process.env.PAYMENTS_PROVIDER_PADDLE_VENDOR_ID!),
  OBSERVABILITY_DATADOG_LOGGING_KEY: process.env.OBSERVABILITY_DATADOG_LOGGING_KEY!,
  USERFLOW_API_KEY: process.env.USERFLOW_API_KEY!,
  WORKFLOWS_API_CALL_VALIDATION_STRING: process.env.WORKFLOWS_API_CALL_VALIDATION_STRING!,
  WORKFLOWS_TEMPORAL_HOSTNAME: process.env.WORKFLOWS_TEMPORAL_HOSTNAME!,
  WORKFLOWS_TEMPORAL_NAMESPACE: process.env.WORKFLOWS_TEMPORAL_NAMESPACE!,
  WORKFLOWS_TEMPORAL_CERT: process.env.WORKFLOWS_TEMPORAL_CERT!,
  WORKFLOWS_TEMPORAL_CERT_KEY: process.env.WORKFLOWS_TEMPORAL_CERT_KEY!,
  TEMPORAL_PORT: process.env.TEMPORAL_PORT!,
  FLOW_EXECUTION_TASK_QUEUE: process.env.FLOW_EXECUTION_TASK_QUEUE!,
  GCP_TALON_ENDPOINT: process.env.GCP_TALON_ENDPOINT!,
  SMTP_HOST: process.env.SMTP_HOST!,
  ENABLE_SLACK_NOTIFICATIONS: process.env.ENABLE_SLACK_NOTIFICATIONS === 'true',
  SLACK_TOKEN: process.env.SLACK_TOKEN!,
  NETWORK_INTEGRATION_TIMEOUT: Number(process.env.NETWORK_INTEGRATION_TIMEOUT),
  NETWORK_INTEGRATION_MAX_CONTENT_LENGTH: Number(process.env.NETWORK_INTEGRATION_MAX_CONTENT_LENGTH),
  NEXT_PUBLIC_FRESHDESK_API_KEY: process.env.NEXT_PUBLIC_FRESHDESK_API_KEY!,
  NEXT_PUBLIC_FRESHDESK_ENPAL_API_KEY: process.env.NEXT_PUBLIC_FRESHDESK_ENPAL_API_KEY!,
};

// Don't touch the part below
// --------------------------

const merged = server.merge(client);

type MergedInput = z.input<typeof merged>;
type MergedOutput = z.infer<typeof merged>;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type MergedSafeParseReturn = z.SafeParseReturnType<MergedInput, MergedOutput>;

// eslint-disable-next-line import/no-mutable-exports
let env: MergedOutput = process.env as unknown as MergedOutput;

if (Boolean(process.env.SKIP_ENV_VALIDATION) === false) {
  const isServer = typeof window === 'undefined';

  const parsed = isServer
    ? merged.safeParse(processEnv) // on server we can validate all env vars
    : client.safeParse(processEnv); // on client we can only validate the ones that are exposed

  if (parsed.success === false) {
    console.error('❌ Invalid environment variables:', parsed.error.flatten().fieldErrors);
    throw new Error('Invalid environment variables');
  }

  // @ts-ignore
  env = new Proxy(parsed.data, {
    get(target, prop) {
      if (typeof prop !== 'string') return undefined;
      // Throw a descriptive error if a server-side env var is accessed on the client
      // Otherwise it would just be returning `undefined` and be annoying to debug
      if (!isServer && !prop.startsWith('NEXT_PUBLIC_'))
        throw new Error(
          process.env.NODE_ENV === 'production'
            ? '❌ Attempted to access a server-side environment variable on the client'
            : `❌ Attempted to access server-side environment variable '${prop}' on the client`,
        );
      // @ts-ignore
      return target[/** @type {keyof typeof target} */ prop];
    },
  });
}

export { env };
