import type { HTMLAttributes, ReactNode } from 'react';

export enum TooltipPosition {
  TopLeft = 'topLeft',
  TopCenter = 'topCenter',
  TopRight = 'topRight',
  BottomLeft = 'bottomLeft',
  BottomCenter = 'bottomCenter',
  BottomRight = 'bottomRight',
}

export interface TooltipContent {
  title?: ReactNode;
  description?: ReactNode;
}

export interface TooltipProps extends Omit<HTMLAttributes<HTMLDivElement>, 'content'> {
  content?: TooltipContent;
  position?: TooltipPosition;
  hide?: boolean;
  hideTip?: boolean;
  children?: ReactNode;
  placeholder?: string;
}
